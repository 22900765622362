import React, { useEffect, useState } from 'react';

import '../styles/LilieId.css';
import xIcon from '../../assets/images/info_icon.svg';
import closeIcon from '../../assets/images/Popup_close.svg';
import successIcon from '../../assets/images/success-icon.svg';
import apiFunctions from '../../services/api-service'
import Loader from '../Loader/loader';

interface AddLilieIdProps {
  obj?: any
  pageNumber?: number;
}

export const AddLilieId: React.FC<AddLilieIdProps> = ({ obj, pageNumber }) => {

  const [isAddLilie, setAddLilie] = useState<boolean>(false);
  const [lilieIdState, setLilieIdState] = useState<string>();
  const [isAddedId, setAddedId] = useState<boolean>(false);
  const [isModalPopMsg, setModalPopMsg] = useState<boolean>(false);
  const [isEnableSyncBtn, setEnableSyncBtn] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [showLoader, SetShowLoader] = useState(false);
  const [error, setError] = useState(false);

  const [clickedOutside, setClickedOutside] = useState(false);

  const isEnableAsyncButton = !!(obj?.lilie_patientNumber); // async button show
  const isDisableAsyncButton = obj?.async_maia_update && obj?.lilie_patientNumber; // async button enable disable

  useEffect(() => {
    setLilieIdState(obj?.lilie_patientNumber)
  }, [obj?.lilie_patientNumber])

  useEffect(() => {
    console.log('log 34 :>>', pageNumber);
    setAddLilie(false);
  }, [pageNumber])

  // lilie id update in brook
  const handleAddLilieId = async () => {
    if (lilieIdState) {
      SetShowLoader(true)
      const payload = {
        lilieid: lilieIdState,
        userid: obj.id
      }
      try {
        const createLilieId = await apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/lilie/addlilieid', payload)
        if (createLilieId) {
          SetShowLoader(false)
          setLilieIdState('')
          setAddLilie(false);
          setAddedId(true);
          setModalPopMsg(true);
          // Lilie ID updated successfully.
          setModalMessage('Lilie ID added successfully.');
          if (!obj?.async_maia_update) {
            setEnableSyncBtn(true);
          }
          return createLilieId;
        }
      }
      catch (err) {
        console.log(err);
      }
    }
  }

  // update lilie id in maia for possible user
  const handleSyncButton = async () => {
    SetShowLoader(false)
    if (obj?.lilie_patientNumber) {
      const payload = {
        lilieid: obj?.lilie_patientNumber,
        email: obj?.email,
        user_id: obj?.id,
        maia_id: obj?.maia_id
      }
      try {
        const createLilieId = await apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/third-party/patient-lilie-id-update-maia', payload)
        if (createLilieId) {
          SetShowLoader(false)
          setLilieIdState('')
          setAddedId(true);
          setModalPopMsg(true);
          setModalMessage('Results successfully synced in My Brook.');
          return createLilieId;
        }
      }
      catch (err) {
        setError(true);
        SetShowLoader(false)
        setLilieIdState('')
        setAddedId(true);
        setModalPopMsg(true);
        setModalMessage(' User not found in My Brook.');
        console.log(err);

      }
    }
  };

  const closePopup = () => {
    setModalPopMsg(false)
    window.location.reload();
  }


  return (

    <div className={`lilie-block ${isEnableAsyncButton ? 'sync-block' : ''}`}>
      {showLoader && <Loader />}
      {isAddLilie ?
        <div className="lilie-input-blk">
          <input
            type="text"
            className="lilie-input"
            onChange={(event: any) => setLilieIdState(event.target.value)}
            value={lilieIdState}
            placeholder="Enter Lilie id" />
          {!isAddedId ?
            <button
              className="lilie-add-btn"
              type="button"
              onClick={handleAddLilieId}>
              {lilieIdState ? "Save" : "Add"}
            </button> : null}
        </div> :
        <span onClick={() => setAddLilie(true)}>{lilieIdState ? lilieIdState : "Add Lilie id"}</span>}

      {(isEnableAsyncButton || isDisableAsyncButton) ?
        <div className='sync-btn-blk'>
          <button
            type='button'
            disabled={isDisableAsyncButton}
            className='sync-btn'
            onClick={handleSyncButton}>
            Sync results in My Brook
          </button>
        </div> : null}
      {/* Render modal popup when user added lilie id */}
      {isModalPopMsg ?
        <div className="lilie-id-modal modal d-block bg-color-popup" role="dialog">
          <div className="modal-dialog contact-attempt-popup modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad pb-0">
                {/* <div className="login-style1">Add contact attempts</div> */}
                <div className="close-white-pos" onClick={() => setModalPopMsg(false)}>
                  <img className="close-white" src={closeIcon} alt="closeIcon" />
                </div>
                <div className='contact-attempts-block'>
                  {error ?
                    <img src={xIcon} alt="failed Icon" />
                    :
                    <div className="success-icon">
                      <img src={successIcon} alt="successIcon" />
                    </div>
                  }
                  {/* <p>{modalMessage}</p> */}
                  <p>{lilieIdState ? 'Lilie ID updated successfully.' : modalMessage}</p>
                </div>
              </div>
              <div className='border-style' />
              <div className="flex justify-end px-8 py-8 border-0">
                <div
                  className="btn btn-primary popup-btn cancel-btn"
                  onClick={() => closePopup()}>
                  Close
                </div>
              </div>
            </div>
          </div>
        </div > : null}
    </div>
  );
}