import React, { useCallback, useContext, useEffect, useState } from 'react'

import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import ErrorPopup from '../components/ModalPopup/ErrorPopup'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import apiFunctions from '../services/api-service'
import '../styles/postcode.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface PostcodeProps {
  Props: any
}

export const Postcode: React.FC<PostcodeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [postcode, setPostcode] = useState<string>('')
  const [showErrorMsg, setshowErrorMsg] = useState('')

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  useEffect(() => {
    setPostcode(theme.postcode)
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.POST_CODE },
    })
  }, [])

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('postCode')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/postcode/check?postcode=' + postcode)
      .then((result) => {
        themeDispatch({
          type: 'SET_POSTCODE',
          payload: { postcode },
        })
        if (result.data) {
          themeDispatch({
            type: 'SET_LOCAL_AUTHORITY',
            payload: { local_authority: result.data.data.local_authority },
          })
        }
        Props.history.push('/postcode-age')
      })
      .catch((err:any) => {
        if(err?.response.data.message === 'Invalid postcode!'){
          Props.history.push('/postcodebarrier')
        }else if(!err?.response.data.status){
          setshowErrorMsg(err?.response?.data?.message)
        }
      })
  }, [postcode])
  return (
    <div className={`bg-color postcode-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <label className={`b1-style ${theme.theme}-txt-global`} htmlFor="postCode">
            To get started, please enter the postcode of the address you would like the test sent to.
          </label>
          <input
            id="postCode"
            type="text"
            className="form-control input-style"
            placeholder="Enter your postcode"
            value={postcode}
            onChange={(e) => {
              setPostcode(String(e.target.value))
            }}
          />
          <div className={`b2-style ${theme.theme}-txt-global`}>
            This is a free service (eligibility criteria apply). Brook is only able to provide test kits for people
            living in certain locations but if we can’t offer you a kit ourselves, we will try to help you find one from
            another provider.{' '}
          </div>
        </div>
      </div>
      <div>
        <div className={`ft-tab jus-end brook-container ${theme.theme}-skin-color`}>
          <Button
            isDisabled={!Boolean(postcode)}
            buttonClassName={`btn btn-default btn-main ${theme.theme}-start-btn-global`}
            buttonText="Next"
            onClick={(e) => proceed()}
          />
        </div>
      </div>
      <ErrorPopup
          showErrorMsg={showErrorMsg}
          html
          setshowErrorMsg={setshowErrorMsg}
          headerClass={`p5-style fo-style ${theme.theme}-txt-global`}
        />
    </div>
  )
}
