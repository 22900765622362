import React, { useEffect, useState } from 'react'
import '../../../styles/AdminStyle/toggleSlider.css'

// Define the prop types
interface StiCountToggleProps {
  onToggle: (newState: boolean,id:string) => void
}


interface StiCountToggleProps {
  status: any,
  id:any,
onToggle: (newState: boolean,id:string) => void

}

const StiCountToggle : React.FC<StiCountToggleProps>= ({
  status,
  id,
  onToggle
}) => {
  
  const [isToggled, setIsToggled] = useState(status)
  const [showLoader, SetShowLoader] = useState(false)
  const [toggleId, SetToggleid] = useState()

  useEffect(()=>{
    SetToggleid(id)
  },[id,status])
  const handleToggle = (e:any) => {
    const newToggleState = !isToggled
    setIsToggled(e.target.checked)
    onToggle(e.target.checked,id)
  }

  return (
    <div>
      <label className={`stiCountToggle ${!isToggled ? 'unchecked' : ''}`}>
        <input type="checkbox" checked={status ? true : false} value={toggleId} onChange={(e:any) => handleToggle(e)} />
        <span className={`slider ${!isToggled ? 'slider-unchecked' : ''}`} />
      </label>
    </div>
  )
}

export default StiCountToggle
