import React, { useEffect, useState } from 'react'

import moment from 'moment'
import ReactQuill from 'react-quill'

import closeIcon from '../../../assets/images/Popup_close.svg'
import { FilterInDashboard } from '../../../components/admin/quickdashboard/FilterInDashboard'
import { GraphComponent } from '../../../components/admin/quickdashboard/GraphComponent'
import { OverviewCard } from '../../../components/admin/quickdashboard/OverviewCard'
import StiCountToggle from '../../../components/admin/quickdashboard/StiCountToggle'
import { StisListCard } from '../../../components/admin/quickdashboard/StisListCard'
import Loader from '../../../components/Loader/loader'
import useAuthContext from '../../../context/provider/AuthContext'
import { resultList } from '../../../data/quickDashboardData'
import apiFunctions from '../../../services/api-service'
import { AdminLayout } from '../AdminLayout'
import { authPriNavMenu } from '../AdminOptions'
import '../../../styles/AdminStyle/quickDashboard.css'

interface QuickDashboardProps {}
export const QuickDashboard: React.FC<QuickDashboardProps> = ({}) => {
  const [showLoader, SetShowLoader] = useState(false)
  const [CardDisabled, setCardDisabled] = useState(false)
  const [internalState, setInternalState] = useState<any>({
    title: 'Requests',
    graphSeries: [],
    graphCategory: [],
    selectedMenu: 'request',
    fromDate: moment(new Date()).subtract(6, 'days').format('DD MMMM YYYY'),
    toDate: moment(new Date()).format('DD MMMM YYYY'),
    selectDay: '6',
    selectSTI: 'all',
  })
  const [internalStateSti, setInternalStateSi] = useState<any>({
    title: 'Requests',
    graphSeries: [],
    graphCategory: [],
    selectedMenu: 'request',
    fromDate: moment(new Date()).subtract(6, 'days').format('DD MMMM YYYY'),
    toDate: moment(new Date()).format('DD MMMM YYYY'),
    selectDay: '6',
    selectSTI: 'all',
  })
  const [overviewState, setOverviwState] = useState<any>({
    positive: 0,
    flagged: 0,
  })
  const [cardList, SetcardList] = useState([])
  const [stiList, SetStiList] = useState([])
  const [stiCount, SetStiCount] = useState([])
  const [toggelId, SettoggleId] = useState('')
  const [deActiveMessage, SetDeActiveMessage] = useState('')
  const [toggleSatus, SetToggleSatus] = useState()

  const {
    state: { user },
  } = useAuthContext()
  const dataval: any = user

  const tabIndex = 0
  useEffect(() => {
    getDashboardDetails()
  }, [
    internalState.title,
    internalState.selectedMenu,
    internalState.fromDate,
    internalState.toDate,
    internalState.selectDay,
    internalState.selectSTI,
  ])

  useEffect(() => {
    stiOrderCount()
  }, [internalStateSti.fromDate, 
     internalStateSti.toDate, 
     internalStateSti.selectDay
  ])

  const [openPopup, setOpenPopup] = useState(false)

  // GET dashboard details
  const getDashboardDetails = async () => {
    SetShowLoader(true)
    try {
      apiFunctions
        .get(
          process.env.REACT_APP_SERVICE_BACKEND_URL +
            `/admin/get-dashboard-details?fromDate=${moment(internalState.fromDate).format(
              'YYYY-MM-DD'
            )}&toDate=${moment(internalState.toDate).format('YYYY-MM-DD')}&type=${
              internalState.selectedMenu
            }&sti_type=${internalState.selectSTI}`
        )
        .then((_result) => {
          const resultData = _result.data.data
          let graphData = []
          switch (internalState.selectedMenu) {
            case 'request':
              graphData.push({
                name: 'Requests',
                data: resultData.yValues,
                color: '#652F91',
              })
              break
            case 'flagged':
              graphData.push({
                name: 'Flagged requests',
                data: resultData.yValues,
                color: '#FF6700',
              })
              break
            case 'result':
              graphData.push({
                name: 'Received',
                data: resultData.yValues,
                color: '#E44498',
              })
              break
            case 'positive':
              graphData.push({
                name: 'Positive',
                data: resultData.yValues,
                color: '#31BDBF',
              })
              break
            default:
              break
          }
          setInternalState({
            ...internalState,
            graphSeries: graphData,
            graphCategory: resultData.xValues,
          })
          setOverviwState({
            ...overviewState,
            positive: resultData.positive_total,
            flagged: resultData.flagged_total,
          })
          let cardinfo = resultList.map((card: any) => {
            if (card.value === 'request') {
              card.count = resultData.total_request
            } else if (card.value === 'flagged') {
              card.count = resultData.flagged_count
            } else if (card.value === 'result') {
              card.count = resultData.result_received
            } else if (card.value === 'positive') {
              card.count = resultData.positive_count
            }
            return card
          })
          SetcardList(cardinfo)
          SetStiList(resultData.STI_Result)
          SetShowLoader(false)
        })
        .catch((err) => {
          SetShowLoader(false)
        })
    } catch (error: any) {
      SetShowLoader(false)
    }
  }

  const stiOrderCount = async () => {
    SetShowLoader(true)
    try {
      apiFunctions
        .get(
          process.env.REACT_APP_SERVICE_BACKEND_URL +
            `/user/get-order-counts?fromDate=${moment(internalStateSti.fromDate).format('YYYY-MM-DD')}&toDate=${moment(
              internalStateSti.toDate
            ).format('YYYY-MM-DD')}`
        )
        .then((_result) => {
          const response_data = _result.data.data
          const sortedUsers = response_data.sort((a: any, b: any) => b.region_name - a.region_name)
          SetStiCount(sortedUsers)
          SetShowLoader(false)
        })
        .catch((err) => {
          SetShowLoader(false)
        })
    } catch (error: any) {
      SetShowLoader(false)
    }
  }
  // Change the Card select
  const handleClick = (type: string) => {
    switch (type) {
      case 'request':
        setInternalState({
          ...internalState,
          title: 'Requests',
          selectedMenu: type,
        })
        break
      case 'flagged':
        setInternalState({
          ...internalState,
          title: 'Flagged requests',
          selectedMenu: type,
        })
        break
      case 'result':
        setInternalState({
          ...internalState,
          title: 'Received',
          selectedMenu: type,
        })
        break
      case 'positive':
        setInternalState({
          ...internalState,
          title: 'Positive',
          selectedMenu: type,
        })
        break
    }
  }

  // Change Days and STIs
  const handleSelectedDaysAndStis = (selectedValue: any, dataType: string) => {
    console.log('selectedValue---', selectedValue)

    if (dataType === 'selecte_day') {
      setInternalState({
        ...internalState,
        selectDay: selectedValue.value,
        fromDate: moment(new Date()).subtract(selectedValue.value, 'days').format('DD MMMM YYYY'),
        toDate: moment(new Date()).format('DD MMMM YYYY'),
      })
    }
    if (dataType === 'selecte_sti') {
      setInternalState({
        ...internalState,
        selectSTI: selectedValue.value,
      })
    }
  }

    // Change Days and STI Order
  const handleSelectedDaysOrder = (selectedValue: any, dataType: string) => {
    if (dataType === 'selecte_day') {
      setInternalStateSi({
        ...internalStateSti,
        selectDay: selectedValue.value,
        fromDate: moment(new Date()).subtract(selectedValue.value, 'days').format('DD MMMM YYYY'),
        toDate: moment(new Date()).format('DD MMMM YYYY'),
      })
    }
  }

  // Change Dates prev and next
  const changeDates = (type: string) => {
    const fromdate = internalState.fromDate
    const todate = internalState.toDate
    const fromdates = internalState.fromDate
    const todates = internalState.toDate
    if (type === 'prev') {
      let fromdate1 = moment(fromdate).subtract(1, 'day')
      let fromdates1 = moment(fromdates).subtract(1, 'day')
      // let todate1 = moment(todate).subtract(1,'day');
      setInternalState({
        ...internalState,
        fromDate: moment(fromdate1).subtract(internalState.selectDay, 'days').format('DD MMMM YYYY'),
        toDate: moment(fromdate1).format('DD MMMM YYYY'),
      })
      setInternalStateSi({
        ...internalStateSti,
        fromDate: moment(fromdates1).subtract(internalStateSti.selectDay, 'days').format('DD MMMM YYYY'),
        toDate: moment(fromdates1).format('DD MMMM YYYY'),
      })
    }
    if (type === 'next') {
      //  let fromdate2 = moment(fromdate).add(1,'day');
      let todate2 = moment(todate).add(1, 'day')
      let todates2 = moment(todates).add(1, 'day')
      if (moment(todate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
        setInternalState({
          ...internalState,
          fromDate: moment(todate2).format('DD MMMM YYYY'),
          toDate: moment(todate2).add(internalState.selectDay, 'days').format('DD MMMM YYYY'),
        })
        setInternalStateSi({
          ...internalStateSti,
          fromDate: moment(todates2).format('DD MMMM YYYY'),
          toDate: moment(todates2).add(internalStateSti.selectDay, 'days').format('DD MMMM YYYY'),
        })
      }
    }
  }

  // submit region status toggle value
  const handleToggleChange = (checked: any, id: string) => {
    SetShowLoader(true)
    if (checked) {
      const payload = {
        status: checked,
      }
      try {
        apiFunctions
          .put(process.env.REACT_APP_SERVICE_BACKEND_URL + `/user/update-region-details?id=${id}`, payload)
          .then((_result) => {
            const resultData = _result.data.data
            if (resultData.status) {
              stiOrderCount()
            }
            SetShowLoader(false)
          })
          .catch((err) => {
            SetShowLoader(false)
          })
      } catch (error: any) {
        SetShowLoader(false)
      }
    } else if (!checked) {
      stiCount.map((msg: any) => {
        if (msg.id === id) {
          SetDeActiveMessage(msg.inactiveMessage)
        }
      })
      setOpenPopup(true)
      SettoggleId(id)
      SetToggleSatus(checked)
      setCardDisabled(true)
      SetShowLoader(false)
    }
  }

  // submit toggle value popup message
  const submitMessage = () => {
    SetShowLoader(true)
    let message_payload
    if (deActiveMessage) {
      message_payload = {
        message: deActiveMessage,
        status: toggleSatus,
      }
    } else {
      SetShowLoader(false)
      return
    }
    try {
      apiFunctions
        .put(process.env.REACT_APP_SERVICE_BACKEND_URL + `/user/update-region-details?id=${toggelId}`, message_payload)
        .then((_result) => {
          const resultData = _result.data.data
          if (!resultData.status) {
            stiOrderCount()
          }
          SetShowLoader(false)
          setOpenPopup(false)
        })
        .catch((err) => {
          SetShowLoader(false)
        })
    } catch (error: any) {
      SetShowLoader(false)
    }
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link']
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ]

  const handleProcedureContentChange = (content: any) => {
    SetDeActiveMessage(content);
  }

  return (
    <div>
      {showLoader && <Loader />}
      <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode">
        <h2 className="day-text" tabIndex={tabIndex}>
          STI test kits and results overview
        </h2>
        <div className="h-30" />
        <OverviewCard overviewState={overviewState} date={moment(new Date()).format('MMMM DD, YYYY')} />
        <div className="h-30" />
        {/* Sti kit order limit */}
        {localStorage.getItem('role') === '1' ? (
          <div className="filter-blk">
            <FilterInDashboard
              FilterData={internalStateSti}
              handleSelectedDaysAndStis={handleSelectedDaysOrder}
              changeDates={changeDates}
              toDate={internalStateSti.toDate}
              isSti={false}
              isOrder={true}
              isPowerBi={false}
            />
            <div className="result-blk sti-count-blk">
              <div className="result-menu-blk">
                {stiCount.map((item: any, idx: number) => {
                  const cardStyle = {
                    backgroundColor: item.status ? item.colourCode : '#D3D3D3',
                  }
                  const nameStyle = {
                    color: item.status ? '' : '#777777',
                  }
                  const countStyle = {
                    color: item.status ? '' : '#777777',
                  }
                  return (
                    <div className="result-menu-items-blk" style={cardStyle} key={`result-menu-items-${idx}`}>
                      <div className="sti-card-header">
                        <h3 className="result-menu-list-label" style={nameStyle}>
                          {item.location}
                        </h3>
                        <StiCountToggle status={item.status} id={item.id} onToggle={handleToggleChange} />
                      </div>
                      <div className="h-30" />
                      <span className="result-count" style={countStyle}>
                        {item.totalOrderCount}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {/* end */}
        {localStorage.getItem('role') === '1' && <div className="h-30" />}
        <div className="filter-blk">
          <FilterInDashboard
            FilterData={internalState}
            handleSelectedDaysAndStis={handleSelectedDaysAndStis}
            stiList={stiList.filter((reps: any) => reps.name !== null)}
            changeDates={changeDates}
            toDate={internalState.toDate}
            isSti
            isPowerBi
          />
          <p className="graph-header-text">Click on the tiles below to update the graph</p>
          <div className="result-blk">
            <div className="result-menu-blk">
              {cardList.map((item: any, idx: number) => {
                const menuBgColor =
                  item.value === 'request'
                    ? 'requests'
                    : item.value === 'flagged'
                    ? 'flagged-result'
                    : item.value === 'result'
                    ? 'received'
                    : item.value === 'positive'
                    ? 'positive-result'
                    : ''
                const title = item.value === 'result' || item.value === 'positive' ? 'Test results' : 'Test kit'
                return (
                  <div
                    className={`result-menu-items-blk ${menuBgColor} ${
                      item.value === internalState.selectedMenu ? menuBgColor + '-shadow' : ''
                    }`}
                    key={`result-menu-items-${idx}`}
                    onClick={() => handleClick(item.value)}
                  >
                    <p className="result-menu-title">{title}</p>
                    <h3 className="result-menu-list-label">{item.name}</h3>
                    <div className="h-30" />
                    <span className="result-count">{item.count}</span>
                  </div>
                )
              })}
            </div>
            <div className="chart-section">
              <GraphComponent
                title={internalState.title}
                seriesData={internalState.graphSeries}
                categoryData={internalState.graphCategory}
                selectDay={internalState.selectDay}
              />
            </div>
          </div>
        </div>
        <div className="h-30" />

        <div className="stis-blk">
          {stiList
            .filter((res: any) => res.name != null)
            .map((item: any, idx: number) => {
              return (
                <React.Fragment key={`stis-menu-item-${idx}`}>
                  <StisListCard data={item} />
                </React.Fragment>
              )
            })}
        </div>
      </AdminLayout>

      {openPopup && (
        <div className="deactivate-sti modal d-block bg-color-popup" role="dialog">
          <div className="modal-dialog contact-attempt-popup modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad pb-0">
                <div className="close-white-pos" onClick={() => setOpenPopup(false)}>
                  <img className="close-white" src={closeIcon} alt="closeIcon" />
                </div>
                <p>
                  <label className="font-bold text-md mb-6">Deactivate Region</label>
                </p>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={deActiveMessage}
                  onChange={handleProcedureContentChange}
                />
              </div>
              <div className="text-center px-8 py-8 border-0">
                <button
                  className="btn btn-primary"
                  disabled={deActiveMessage.replace(/<(.|\n)*?>/g, '').trim() ? false : true}
                  onClick={() => submitMessage()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
